const ACCOUNT_TOKEN = 'ACCOUNT_TOKEN';
const ACCOUNT_COID_TOKEN = 'ACCOUNT_COID_TOKEN';

const accountToken = {
    get token() {
        return window.localStorage.getItem(ACCOUNT_TOKEN);
    },
    set token(value) {
        window.localStorage.setItem(ACCOUNT_TOKEN, value);
    },
    get coid() {
        return window.localStorage.getItem(ACCOUNT_COID_TOKEN) || 1;
    },
    set coid(value) {
        window.localStorage.setItem(ACCOUNT_COID_TOKEN, value);
    },
};

export default accountToken;
