import message_zhCN from './zh-CN.message';
import zhCN from 'antd/es/locale/zh_CN';
import message_enUS from './en-US.message';
import enUS from 'antd/es/locale/en_US';
import get from 'lodash/get';

export const zh_CN = {
  message: message_zhCN,
  antd: zhCN,
  locale: 'zh-CN'
};

export const en_US = {
  message: message_enUS,
  antd: enUS,
  locale: 'en-US'
};

export const getMessage = (name, options) => {
  const language = window.localStorage.getItem('ERC_CLIENT_LOCAL') || (navigator.language || navigator.userLanguage) || 'zh-CN';
  let formatMessage = get({
    'zh-CN': message_zhCN,
    'en-US': message_enUS
  }, `[${language}][${name}]`, message_zhCN[name]);
  options = Object.assign({}, options);
  Object.keys(options).forEach((key) => {
    formatMessage = formatMessage.replace(new RegExp(`\{${key}}`, 'g'), options[key]);
  });

  return formatMessage;
};
