import { useEffect } from 'react';
import { Layout } from 'antd';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import style from './style.module.scss';
import { getUserInfo } from '../apis/account';
import { createWithFetch } from '@kne/react-fetch';
import merge from 'lodash/merge';
import { useContext, Provider } from '../globalContext';
import { preset as formPreset } from '@kne/react-form-antd';
import classnames from 'classnames';
import {get} from 'lodash';

const { Content } = Layout;

const AfterLoginLayout = createWithFetch(merge({}, getUserInfo, {
  error: (e) => {
    const searchParams = new URLSearchParams(window.location.search);
    const referer = window.location.pathname + window.location.search;
    searchParams.append('referer', referer);
    searchParams.append('msg', e.message);
    return <Navigate to={'/error?' + searchParams.toString()} />;
  }
}))(({ data }) => {
  const location = useLocation();
  const info = useContext();
  const isInterviewer = (data.permissions || []).indexOf('group:interview') !== -1;
  const isAdmin = (data.permissions || []).indexOf('group:mgr') !== -1;
  useEffect(() => {
    if (isInterviewer && !isAdmin) {
      document.body.classList.add('body-interviewer');
    } else {
      document.body.classList.remove('body-interviewer');
    }
    formPreset({
      size: isInterviewer && !isAdmin ? 'large' : 'middle'
    });
  }, [isInterviewer, isAdmin]);

  if (location.pathname === '/' && !isAdmin && isInterviewer) {
    return <Navigate to='/interview' />
  }

  return <Provider value={{
    ...info,
    isInterviewer,
    userInfo: {
      ...(data.platformUserLogin || {}),
      coId: get(data, 'companyUserInfo.coId'),
      uid: get(data, 'platformUserLogin.id'),
      isAdmin: get(data, 'companyUserInfo.isAdmin'),
      isLeader: get(data, 'companyUserInfo.isLeader'),
      orgId: get(data, 'companyUserInfo.orgId') || [],
      roleIds: get(data, 'appUserInfo.roleIds') || [],
      sharingGroupList: data.sharingGroupList || [],
      dataRangeType: get(data, 'appUserInfo.dataRangeType') || null,
      isBinding: data.isBinding
    },
    permissions: data.permissions || []
  }}>
    <Layout
      className={classnames(style['page-layout'], isInterviewer ? 'page-layout-interviewer' : '', location.pathname.split('/').filter((item) => !!item)[0] || 'index')}>
      <Content className={style['page-content']}><Outlet /></Content>
    </Layout>
  </Provider>;
});

export default AfterLoginLayout;
