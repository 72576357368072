import '@fa-talent/project-base/dist/index.css';
import { ajax as _ajax } from '@fa-talent/project-base';
import { preset as formPreset, RULES } from '@kne/react-form-antd';
import { preset as enhancePreset } from '@kne/antd-enhance';
import { zh_CN, en_US, getMessage } from './locales';
import { ConfigProvider } from '@fa-talent/project-base';
import { IntlProvider } from 'react-intl';
import { preset as fetchPreset } from '@kne/react-fetch';
import { Result } from 'antd';
import accountToken from './accountToken';


export const ajax = _ajax;

ajax.interceptors.request.use((config) => {
  config.headers = Object.assign({}, {
    'X-FAT-TOKEN': accountToken.token,
    'X-FAT-CO-ID': accountToken.coid,
  });
  config.timeout = 10000;
  return config;
});

const _oldREQ = RULES.REQ;

formPreset({
  type: 'inner',
  rules: {
    REQ: (...args) => {
      return Object.assign({}, _oldREQ(...args), { errMsg: `%s${getMessage('requiredTip')}` });
    }
  },
  field: {
    upload: {
      getHeaders: () => {
        return {
          'X-FAT-TOKEN': accountToken.token
        };
      }
    }
  }
});

enhancePreset({
  withLayerInstall: (WrappedComponent) => ({ language, ...props }) => {
    const _language = (language || window.localStorage.getItem('ERC_CLIENT_LOCAL')) === 'en-US' ? en_US : zh_CN;
    return <IntlProvider locale={_language.locale} messages={_language.message}>
      <ConfigProvider locale={_language.antd}>
        <WrappedComponent {...props} language={language} />
      </ConfigProvider>
    </IntlProvider>;
  }
});

fetchPreset({
  error: (error) => {
    return <Result status={error.code || 'error'} subTitle={error.message || '请求发生错误'} />;
  }
});
