import loadable from '@loadable/component';
import { Spin } from 'antd';
import PermissionsPage from '../userFunPermissions';

const loadableWithProps = (func, permissions) => {
  const LoadableComponent = loadable(func, {
    fallback: <Spin style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} />
  });
  if (permissions) {
    return (props) => <PermissionsPage funs={permissions}><LoadableComponent {...props} /></PermissionsPage>;
  }
  return LoadableComponent;
};

export const pages = {
  Home: loadableWithProps(() => import('./Home'), ['group:mgr']),
  Position: loadableWithProps(() => import('./Position'), ['group:mgr']),
  Candidate: loadableWithProps(() => import('./Candidate'), ['group:mgr', 'group:interview']),
  MyInterview: loadableWithProps(() => import('./MyInterview'), ['group:interview']),
  Login: loadableWithProps(() => import('./Login')),
  Error: loadableWithProps(() => import('./Error'))
};
