import { Result } from 'antd';
import { useMemo } from 'react';
import { useContext } from './globalContext';
import { getMessage } from './locales';

export const useFunPermissions = (funs) => {
  const { permissions = [] } = useContext();
  return useMemo(() => {
    const _funs = funs ? Array.isArray(funs) ? funs : [funs] : permissions;
    const result = {};
    _funs.forEach(key => {
      result[key] = permissions.some(x => x === key);
    })
    return result;
  }, [funs, permissions]);
};

export const hasPermissionByKey = (funsMap = {}, keys) => {
  const list = []
  Object.keys(funsMap).forEach(key => {
    if (funsMap[key]) list.push(key)
  });
  const _keys = keys ? Array.isArray(keys) ? keys : [keys] : [];
  return list.some(x => _keys.some(c => c === x));
}

const PermissionsPage = ({ children, noPermission, funs = [] }) => {
  const list = useFunPermissions(funs);
  const hasAuth = hasPermissionByKey(list, funs);
  if (hasAuth) return children;
  return noPermission;
};

PermissionsPage.defaultProps = {
  noPermission: <Result
    status="403"
    // "您暂无权限，请联系管理员"
    subTitle={getMessage('noPermissionText')}
  />
}

export default PermissionsPage;