const message = {
    myInterview: "我的面试",
    candidate: '候选人',
    male: '男',
    female: '女',
    resumeUpload: '简历上传于',
    position: '应聘职位',
    resume: '简历',
    interviewProgress: '面试流程',
    interviewTime: '面试时间',
    onlineSite: '现场面试',
    phoneInterview: '电话面试',
    videoInterview: '视频面试',
    feedback: '面试反馈',
    feedbackPlaceholder: '请输入面试评价',
    interviewResult: '面试结果',
    addInterview: '加试',
    submit: '提交',
    interviewer: '面试官',
    passCandidateState: '已通过',
    failCandidateState: '淘汰',
    inProgressCandidateState: '面试中',
    paasProgressState: '通过',
    failProgressState: '不通过',
    preScheduledProgressState: '待约面',
    pendingProgressState: '待反馈',
    cancel: '取消',
    submitSuccess: '提交反馈成功！',
    failToSubmit: '提交反馈失败，请重试',
    submitFeedbackNext: '{interviewer}已完成对{candidate}的面试反馈，请安排后续流程。',
    scheduledForyou: '{interviewer}为您安排了{candidate}的面试。',
    candidateState: '候选人状态',
    endInterview: '结束面试',
    appointInterview: '预约面试',
    disuseCandidate: '淘汰候选人',
    candidateName: '候选人姓名',
    candidateNameEn: '候选人姓名-英文',
    interviewWays: '面试方式',
    interviewWaysRemark: '面试信息',
    interviewWaysRemarkAddress: '面试地点',
    interviewWaysRemarkAddressPlaceholder: '请输入面试地点',
    feedbackResult: '反馈结果',
    viewCandidate: '查看候选人',
    myInterviewTotalCount: '共{total}个',
    interviewEvaluation: '面试评价',
    scheduledInterview: '已安排面试',
    addRoundInterviews: '选择面试官',
    intervieworPlaceholder: '面试官',
    submitFeedback: '提交反馈',
    disuseCandidateInfo: '当前候选人未完成全部面试，确认淘汰，未进行的面试将被取消。',
    update: '修改',
    updateInterviewTitle: '修改面试',
    skipRound: '跳过本轮',
    paasFeedbackPlaceholder: '请输入面试评价',
    addFeedbackPlaceholder: '请输入面试评价',
    startPlaceholder: '请选择开始时间',
    endPlaceholder: '请选择结束时间',
    requiredTip: '不能为空',
    skipActionTip: '确认跳过当前面试官？',
    skipActionTipDesc: '点击确定，候选人将直接进入下轮面试。',
    sure: '确定',
    disuseReason: '淘汰原因',
    disuseReasonPlaceholder: '请输入候选人淘汰原因',
    noInterviewEmpty: '暂无面试信息',
    dingdingWarning: '请在钉钉应用中打开页面',
    dingdingLogin: '正在获取钉钉登录授权...',
    loginSuccess: '登录成功',
    authorizationFailed: '登录授权失败',
    internalError: '发生了一个内部错误',
    positionName: '职位名称',
    positionNameEn: '职位名称(英文)',
    jd: '职位JD',
    checkJd: '查看JD',
    positionDes: '职位说明',
    recruitmentPosition: '招聘职位',
    positionEmpty: '暂无招聘职位',
    newPosition: '新增职位',
    addSuccess: '添加成功',
    updateSuccess: '修改成功',
    removeSuccess: '删除成功',
    listView: '列表视图',
    cardView: '卡片视图',
    createTime: '创建于',
    positionCount: '共{count}个职位',
    describe: '描述',
    options: '操作',
    edit: '编辑',
    del: '删除',
    deleteSuccess: '删除成功',
    editPosition: '修改职位',
    editSuccess: '编辑成功',
    createdAt: '创建于',
    packUp: '收起',
    expand: '展开',
    candidateList: '候选人名单',
    candidateEmpty: '暂无候选人',
    newCandidate: '新增候选人',
    editCandidate: '修改候选人',
    justInterview: '仅看面试中',
    resumeUploadAt: '简历上传于',
    viewDetails: '查看详情',
    candidateGender: '候选人性别',
    clickUpload: '点击上传',
    uploadTips: '允许文件格式：.rar,.zip,doc,docx,pdf,jpg 单个文件大小不超过10M',
    updateInterviewer: '修改面试官',
    searchInterviewerPlaceholder: '请输入面试官姓名',
    delConfirm: '你确定要删除吗？',
    selectInterviewer: '选择面试官',
    addInterviewer: '添加面试官',
    loadComplete: '加载完成',
    disuseCandidateEndTip: '候选人不再应聘当前职位，面试终止。',
    noPermissionText: '您暂无权限，请联系管理员',
    viewAllInterview: '查看全部面试',
    skipSuccessTip: '已跳过该面试官',
    interviewWaysRemarkPlaceholder: '电话，视频会议号，或其他需同步面试官的信息…',
    addInterviewSuccessTip: '加试成功！',
    disuseSuccessTip: '淘汰候选人成功！',
    appointInterviewSuccessTip: '预约面试成功!',
    addInterviewFeedbackSuccessTip: '加试并反馈成功！',
    noCandidateInterviewEmpty: '未安排当前候选人面试',
    reset: '重置',
    common_404: '找不到资源',
    common_70002: '候选人面试流程已变更。如有疑问，请联系招聘助理。',
    common_70003: '创建/修改面试官日程失败，请重试。',
    common_70004: '取消面试官日程失败，请手动检查并取消日程安排',
    position_option_fail: '职位下有候选人，无法执行该操作',
    common_fail_tip: '操作失败，请重试！',
    updateInterviewSuccessTip: '修改面试成功！',
    disuseOtherTip: '被他人淘汰',
    interviewProgressResult: '面试进展',
    notSupportPreview: '暂不支持预览',
    loadFailed: '加载文件失败',
    placeholderPrefix: '请输入{label}',
    fileMaxLengthError: '文件数不能超过{count}个',
    fileSizeError: '文件大小不能超过{size}',
    positionJD: '职位JD',
    afterRoundTo: '本轮后加试，面试官:',
    cancelAddInterview: '取消加试',
    addInterviewLower: '加试',
    defaultEditorFeedback: "<p><strong>#候选人优势</strong>&nbsp;&nbsp;</p>" +
        "<p></p>" +
        "<p></p>" +
        "<p></p>" +
        "<hr />" +
        "<p><strong>#候选人劣势</strong>&nbsp;&nbsp;</p>" +
        "<p></p>" +
        "<p></p>" +
        "<p></p>" +
        "<hr />" +
        "<p><strong>#面试总结</strong>&nbsp;&nbsp;</p>" +
        "<p></p>" +
        "<p></p>" +
        "<p></p>",
    recommendedReason: '推荐理由',
    positionState: '职位状态',
    positionStateAll: '全部',
    positionStateInProgress: '进行中',
    positionStateComplete: '已完成',
    positionStateClose: '已关闭',
    positionCompleteTips: '当前职位下仍有{count}位候选人在面试中。确认已完成该职位招聘？点击确认，职位状态将变更为【已完成】',
    positionCloseTips: '当前职位下仍有{count}位候选人在面试中。确认关闭该职位招聘？点击确认，职位状态将变更为【已关闭】',
    positionCompleteEmptyTips: '确认已完成该职位招聘？点击确认，职位状态将变更为【已完成】',
    positionCloseEmptyTips: '确认关闭该职位招聘？点击确认，职位状态将变更为【已关闭】',
    recruitmentDays: '招聘天数',
    optionSuccess: '操作成功',
    close: '关闭',
    complete: '完成',
    recommendedChannels: '推荐渠道',
    other: '其他',
    candidatePhotos: '候选人照片',
    reasonForRecommendation: '推荐理由',
    placeholderEditor: '请输入...',
    candidateNameLabel: '候选人姓名',
    positionSearchPlaceholder: '搜索职位名称...'
}

export default message;
