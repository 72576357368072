import { Routes, Route } from 'react-router-dom';
import AfterLogin from './layout/AfterLogin';
import { pages } from './pages';

const {
  Home,
  Position,
  Candidate,
  MyInterview,
  Login,
  Error
} = pages;

function App() {
  return (<Routes>
      <Route path='/' element={<AfterLogin />}>
        <Route index element={<Home />} />
        <Route path='position/:id' element={<Position />} />
        <Route path='candidate/:id' element={<Candidate />} />
        <Route path='interview' element={<MyInterview />} />
      </Route>
      <Route path='login' element={<Login />} />
      <Route path='error' element={<Error />} />
    </Routes>
  );
}

export default App;
