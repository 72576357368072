import { useEffect, useState } from 'react';
import { ConfigProvider } from '@fa-talent/project-base';
import { IntlProvider } from 'react-intl';
import { zh_CN, en_US } from './locales';
import { Provider } from './globalContext';

const LocalProvider = ({ children }) => {
    const localLanguage = window.localStorage.getItem('ERC_CLIENT_LOCAL') === 'en-US' ? en_US : zh_CN;
    if (!window.localStorage.getItem('ERC_CLIENT_LOCAL')) {
        window.localStorage.setItem('ERC_CLIENT_LOCAL', 'zh-CN');
    }
    const [language, setLanguage] = useState(localLanguage);
    useEffect(() => {
        setLanguage(language);
    }, [language])
    return <Provider value={{
        language,
        setLanguage,
    }}>
        <IntlProvider locale={language.locale} messages={language.message}>
            <ConfigProvider locale={language.antd}>
                {children}
            </ConfigProvider>
        </IntlProvider>
    </Provider>
}

export default LocalProvider;