const message = {
    myInterview: "My Interview",
    candidate: 'Candidate',
    male: 'Male',
    female: 'Female',
    resumeUpload: 'Resume Upload',
    position: 'Position',
    resume: 'Resume',
    interviewProgress: 'Interview Process',
    interviewTime: 'Time',
    onlineSite: 'Face to Face',
    phoneInterview: 'Phone',
    videoInterview: 'Video',
    feedback: 'Feedback',
    feedbackPlaceholder: 'Please Enter Your Evaluation',
    interviewResult: 'Result',
    addInterview: 'Additional Round',
    submit: 'Submit',
    interviewer: 'Interviewer',
    passCandidateState: 'Pass',
    failCandidateState: 'Reject',
    inProgressCandidateState: 'Interviewing',
    paasProgressState: 'Pass',
    failProgressState: 'Reject',
    preScheduledProgressState: 'Pre-Scheduled',
    pendingProgressState: 'Waiting for feedback',
    cancel: 'Cancel',
    submitSuccess: 'Submit Successfully',
    failToSubmit: 'Fail to Submit, Please Try Again',
    submitFeedbackNext: '{interviewer} Has Submitted Feedback for {candidate}, Follow Up Please.',
    scheduledForyou: '{interviewer} Scheduled an Interview with {candidate} for You',
    candidateState: 'Candidate State',
    endInterview: 'End Interview',
    appointInterview: 'Schedule Interview',
    disuseCandidate: 'Reject',
    candidateName: 'Name-Chinese',
    candidateNameEn: 'Name',
    interviewWays: 'Type',
    interviewWaysRemark: 'Infomation',
    interviewWaysRemarkAddress: 'Address',
    interviewWaysRemarkAddressPlaceholder: 'Please Enter Interview Address',
    feedbackResult: 'Submit Feedback',
    viewCandidate: 'Detail',
    myInterviewTotalCount: 'Total {total}',
    interviewEvaluation: 'Evaluation',
    scheduledInterview: 'Scheduled',
    addRoundInterviews: 'Select Interviewer',
    intervieworPlaceholder: 'Interviewer',
    submitFeedback: 'Submit',
    disuseCandidateInfo: "The Candidate doesn't Finish All Interviews, The Rest will be Cancelled if Rejected Now.",
    update: 'Edit',
    updateInterviewTitle: 'Edit Interview',
    skipRound: 'Skip',
    paasFeedbackPlaceholder: 'Please Enter Your Evaluation',
    addFeedbackPlaceholder: 'Please Enter Your Evaluation',
    startPlaceholder: 'Start Time',
    endPlaceholder: 'End Time',
    requiredTip: ' Reuqired',
    skipActionTip: 'Confirm to Skip?',
    skipActionTipDesc: 'The Candidate Will Enter Next Round.',
    sure: 'Confirm',
    disuseReason: 'Reason for Rejection',
    disuseReasonPlaceholder: 'Please Enter the Reject Reason',
    noInterviewEmpty: 'No Interview Yet',
    dingdingWarning: 'Please View the Page in DingTalk',
    dingdingLogin: 'Obtaining Login Authorization',
    loginSuccess: 'Login Successfully',
    authorizationFailed: 'Login Authorization Failed',
    internalError: 'Internal Error',
    positionName: 'Position',
    positionNameEn: 'Position(English)',
    jd: 'Job Description',
    checkJd: 'Check JD',
    positionDes: 'Description',
    interviewProcess: 'Interview process',
    recruitmentPosition: 'Position',
    positionEmpty: 'There is No Position.',
    newPosition: 'New position',
    addSuccess: 'Create Successfully',
    updateSuccess: 'Edit Successfully',
    removeSuccess: 'Delete Successfully',
    listView: 'Table View',
    cardView: 'Card View',
    createTime: 'Created Date',
    positionCount: 'Total {count}',
    describe: 'Description',
    options: 'Operation',
    edit: 'Edit',
    del: 'Delete',
    deleteSuccess: 'Delete Successfully',
    editPosition: 'Edit',
    editSuccess: 'Edit Successfully',
    createdAt: 'Created on',
    packUp: 'Fold',
    expand: 'Unfold',
    candidateList: 'List of Candidate',
    candidateEmpty: 'No Candidate',
    newCandidate: 'New Candidate',
    editCandidate: 'Edit Canditate',
    justInterview: 'Only Interviewing',
    resumeUploadAt: 'Upload on',
    viewDetails: 'View Detail',
    candidateGender: 'Gender',
    clickUpload: 'Click Upload',
    uploadTips: 'Allowed file formats: rar,. Zip, Doc, docx, PDF, JPG the size of a single file shall not exceed 10M',
    updateInterviewer: 'Modify Interviewer',
    searchInterviewerPlaceholder: 'Search',
    delConfirm: 'Confirm to Delete?',
    selectInterviewer: 'Select Interviewer',
    addInterviewer: 'Add Interviewer',
    loadComplete: 'Load Completely',
    disuseCandidateEndTip: 'The Interview is Suspend because the Candidate is No Longer Applying for Current Postition',
    noPermissionText: 'No Permission Now, Please Contact the Admin',
    viewAllInterview: 'View All Interviews',
    skipSuccessTip: 'Skip Successfully',
    interviewWaysRemarkPlaceholder: 'Phone No., Meeting ID…',
    addInterviewSuccessTip: 'Add Successfully!',
    disuseSuccessTip: 'Reject Successfully!',
    appointInterviewSuccessTip: 'Schedule Successfully!',
    addInterviewFeedbackSuccessTip: 'Submit Successfully!',
    noCandidateInterviewEmpty: 'There is No Interview Scheduled for You',
    reset: 'Reset',
    common_404: '404 Not Found',
    common_70002: 'The Interview Process is Updated, Please Contact the Recruitment Assistant.',
    common_70003: "Fail to Create/Modify Interviewer's Schedule, Please Try Again.",
    common_70004: "Fail to Cancel the Interviewer's Schedule, Please Check Again.",
    common_fail_tip: 'Failed, Please Try Again!',
    updateInterviewSuccessTip: 'Edit Successfully!',
    disuseOtherTip: 'Rejected by Others',
    interviewProgressResult: 'Interview Process',
    notSupportPreview: 'Preview is Not Supported Now',
    loadFailed: 'Failed to load file.',
    placeholderPrefix: 'Please Input {label}',
    fileMaxLengthError: 'The Number of Files Cannot Exceed {count}',
    fileSizeError: 'The File Size Cannot Exceed {size}',
    positionJD: 'Job Description',
    afterRoundTo: 'Interviewer for Additional Round:',
    cancelAddInterview: 'Back',
    addInterviewLower: 'Additional Round',
    defaultEditorFeedback: "<p><strong>#Candidate Advantage</strong>&nbsp;&nbsp;</p>" +
        "<p></p>" +
        "<p></p>" +
        "<p></p>" +
        "<hr />" +
        "<p><strong>#Candidate Disadvantage</strong>&nbsp;&nbsp;</p>" +
        "<p></p>" +
        "<p></p>" +
        "<p></p>" +
        "<hr />" +
        "<p><strong>#Summary</strong>&nbsp;&nbsp;</p>" +
        "<p></p>" +
        "<p></p>" +
        "<p></p>",
    recommendedReason: 'Recommendations',
    positionState: 'Status',
    positionStateAll: 'All',
    positionStateInProgress: 'Ongoing',
    positionStateComplete: 'Completed',
    positionStateClose: 'Closed',
    positionCompleteTips: 'There are {count} Candidates in the Process. Pressing confirm, the status will change to "Completed"',
    positionCloseTips: 'There are {count} Candidates in the Process. Pressing confirm, the status will change to "Closed"',
    positionCompleteEmptyTips: 'Pressing Confirm，the status will change to  "Completed"',
    positionCloseEmptyTips: 'Pressing Confirm, the status will change to "Closed"',
    recruitmentDays: 'Recruitment Days',
    optionSuccess: 'Success',
    close: 'Close',
    complete: 'Complete',
    recommendedChannels: 'Source',
    other: 'Other',
    candidatePhotos: 'Candidate Photo',
    reasonForRecommendation: 'Recommendations',
    placeholderEditor: 'Please Input...',
    candidateNameLabel: 'Name',
    positionSearchPlaceholder: 'Search position name...'
}

export default message;
